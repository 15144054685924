const storageKey = `__cookies-is-accepted`;

const Classnames = {
  card: `agent-card`,
  hidden: `hidden`,
};

const Selectors = {
  root: `.cookies-notification`,
  accept: `.cookies-notification__action_type_accept`,
  close: `.cookies-notification__action_type_close`,
};

const Regexps = {
  hidden: new RegExp(` *${Classnames.hidden}`, `ig`),
};

(function () {
  let isAccepted = false;

  try {
    isAccepted = localStorage.getItem(storageKey);
  } catch (error) {
    console.error(error);
  }

  if (isAccepted) return;

  let rootNode = document.querySelector(Selectors.root);

  rootNode.className = rootNode.className.replace(Regexps.hidden, ``);

  let acceptNode = rootNode.querySelector(Selectors.accept);
  let closeNode = rootNode.querySelector(Selectors.close);

  if (acceptNode) {
    acceptNode.addEventListener('click', accept);
    closeNode.addEventListener('click', close);
  } else {
    closeNode.addEventListener('click', accept);
  }

  function accept() {
    try {
      localStorage.setItem(storageKey, 'accepted');
    } catch (error) {
      console.error(error);
    }

    close();
  }

  function close() {
    rootNode.className += ` ${Classnames.hidden}`;
  }
})();
